var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-container',{attrs:{"fluid":""}},[_c('v-row',[_c('v-col',{staticClass:"col-md-12 mr-0 pr-0 pt-0 mb-0"},[_c('v-data-table',{staticClass:"elevation-1",attrs:{"headers":_vm.headers,"items":_vm.desserts,"items-per-page":_vm.itemPerPage,"dense":""},scopedSlots:_vm._u([{key:"item.NominalPlantPower",fn:function(ref){
var item = ref.item;
return [_c('span',{staticClass:"mr-3 d-flex"},[_vm._v(" "+_vm._s(item.NominalPlantPower)+" ")])]}},{key:"item.error",fn:function(ref){
var item = ref.item;
return [(item.error > 0)?_c('v-chip',{staticClass:"ma-2 mt-1 pt-0 mb-1 pb-0",attrs:{"color":"red","label":"","text-color":"white","width":"20"}},[_vm._v(" "+_vm._s(item.error)+" ")]):_c('v-chip',{staticClass:"ma-2 mt-1 pt-0 mb-1 pb-0",attrs:{"color":"green","label":"","width":"20","text-color":"white"}},[_vm._v(" "+_vm._s(item.error)+" ")])]}},{key:"item.temp",fn:function(ref){
var item = ref.item;
return [_c('v-col',{staticClass:"mb-0 pb-0 d-flex pt-0 mt-0",attrs:{"cols":"12","md":"6"}},[_c('v-img',{attrs:{"src":item.icon,"alt":"Sunny image","width":"30"}}),_c('span',{staticClass:"mr-3 text-h5"},[_vm._v(" "+_vm._s(item.temp)+"°C ")])],1)]}},{key:"item.prefix",fn:function(ref){
var item = ref.item;
return [_c('v-btn',{attrs:{"color":"blue darken-1","text":""},on:{"click":function($event){return _vm.farmGraphic(item, 'screen')}}},[_vm._v(" Anlık ")]),_c('v-btn',{attrs:{"color":"blue darken-1","text":""},on:{"click":function($event){return _vm.farmGraphic(item, 'alarm')}}},[_vm._v(" Hata ")]),_c('v-btn',{attrs:{"color":"blue darken-1","text":""},on:{"click":function($event){return _vm.farmOpen(item, 'detail')}}},[_vm._v(" Detay ")]),_c('v-btn',{attrs:{"color":"blue darken-1","text":""},on:{"click":function($event){return _vm.farmOpen(item, 'setting')}}},[_vm._v(" Firma Yönetim ")]),_c('v-btn',{attrs:{"color":"blue darken-1","text":""},on:{"click":function($event){return _vm.farmOpen(item, 'ayar')}}},[_vm._v(" Saha Yönetim ")])]}}]),model:{value:(_vm.selected),callback:function ($$v) {_vm.selected=$$v},expression:"selected"}})],1)],1),_c('Screen',{ref:"graphicdialog",attrs:{"dialog":_vm.graphicdialog,"farm":_vm.selectedfarm},on:{"screendialog":_vm.screendialogclose}}),_c('Alarm',{attrs:{"dialog":_vm.errordialog,"farm":_vm.selectedfarm},on:{"alarmdialog":_vm.alarmdialogclose}})],1)}
var staticRenderFns = []

export { render, staticRenderFns }