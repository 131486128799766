<template>
  <div>
    <PanelGroup
      v-if="componentsize"
      :rest="rest"
    />
    <FarmList @totalStats="setTotalStats" />
  </div>
</template>
<script>
// eslint-disable-next-line import/extensions
import PanelGroup from '@/views/components/Farm/PanelGroup';
// eslint-disable-next-line import/extensions
import FarmList from '@/views/components/Farm/FarmList';

export default {
  /// / şirket, santral listesi anlık durum sayfası
  components: {
    PanelGroup,
    FarmList,
  },
  data() {
    return {
      rest: {},
      componentsize: 0,
    };
  },
  created() {

  },
  methods: {
    setTotalStats(item) {
      // this.rest = item;DailyEnerg
      this.rest = [
        {
          icon: 'mdi-solar-panel',
          title: 'Kurulu Güç',
          value: item.NominalPlantPower.toFixed(2),
          color: 'orange',
          unit: 'kWp',
        },
        {
          icon: 'mdi-solar-power',
          title: 'Toplam Üretim',
          value: item.TotalEnergy.toFixed(2),
          color: 'orange',
          unit: 'MW',
        },
        {
          icon: 'mdi-solar-power',
          title: 'Günlük Enerji',
          value: item.DailyEnergy.toFixed(2),
          color: 'orange',
          unit: 'kWh',
        },
        {
          icon: 'mdi-solar-power',
          title: 'Anlık Üretim',
          value: item.IstantaneousPower.toFixed(2),
          color: 'orange',
          unit: 'kW',
        },
        /*  {
            icon: "mdi-flash",
            title: "Performans",
            value: item.PerformanceRatio,
            color: "red",
            unit: "%",
          }, */
        {
          icon: 'mdi-fire',
          title: 'CO2',
          value: item.CO2Avoided.toFixed(2),
          color: 'primary',
          unit: '',
        },
        {
          icon: 'mdi-pine-tree',
          title: 'Ağaç Eşdeğer',
          value: item.EquivalentTrees.toFixed(2),
          color: 'green',
          unit: '',
        },
      ];
      this.$nextTick(() => {
        this.componentsize += 1;
      });
    },
  },
};
</script>
